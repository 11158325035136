import React, { lazy } from 'react'
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom'
import AccessibleNavigationAnnouncer from './components/AccessibleNavigationAnnouncer'
import { UserProvider } from "./context/UserContext"

const Page404 = lazy(() => import('./pages/404'))
const Layout = lazy(() => import('./containers/Layout'))
const Login = lazy(() => import('./pages/Login'))

function App() {
  return (
    <>
      <UserProvider>
        <Router>
          <AccessibleNavigationAnnouncer />
          <Routes>

            <Route path="*" element={<Page404 />} />
            <Route path="/login" element={<Login />} />
            {/* Place new routes over this */}
            <Route path="/app/*" element={<Layout />} />
            {/* If you have an index page, you can remothis Redirect */}
            <Route path="/" element={<Navigate to="/login" />} />

          </Routes>
        </Router>
      </UserProvider>
    </>
  )
}

export default App
