// Import the functions you need from the SDKs you need
import app from "./firebaseConfig"
import { GoogleAuthProvider, signInWithPopup, getAuth, signOut } from "firebase/auth";
import { getFirestore, where, query, addDoc, getDocs, collection } from "firebase/firestore";

const auth = getAuth(app);
const db = getFirestore(app);

async function signInWithGoogle(): Promise<string | null> {
	try {
		const googleProvider = new GoogleAuthProvider();
		googleProvider.addScope("email");
		// always prompt for which gmail account to use
		googleProvider.setCustomParameters({ prompt: "select_account" });
		const res = await signInWithPopup(auth, googleProvider)
		const user = res.user;

		if (!user.email.endsWith("@mxcglobal.com")) {
			await logout()
			// throw exception to force user to sign in again
			return "Email does not end with @mxcglobal.com, denied"
		}

		const q = query(collection(db, "users"), where("uid", "==", user.uid))
		const docs = await getDocs(q);
		if (docs.docs.length === 0) {
			await addDoc(collection(db, "users"), {
				uid: user.uid,
				name: user.displayName,
				authProvider: "google",
				email: user.email
			})
		}

		return null
	} catch (err) {
		console.error(err)
	}
};

async function logout(): Promise<void> {
	await signOut(auth)
}

export { auth, logout, signInWithGoogle }