import React from "react"

import { useAuthState } from "react-firebase-hooks/auth"
import { auth } from "../services/firebaseAuth"
import ThemedSuspense from "../components/ThemedSuspense"


const UserContext = React.createContext(null)

export function UserProvider({ children }) {
    const [user, loading] = useAuthState(auth)

    if (loading)
        return <ThemedSuspense />

    return (
        <UserContext.Provider value={{ user: user, loading: loading }}>
            {children}
        </UserContext.Provider>
    )
}

export default UserContext