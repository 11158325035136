import React from "react"
import Spinner from "./Spinner"

function ThemedSuspense() {
  return (
    <div className="w-full h-screen flex items-center justify-center p-6 text-lg font-medium text-gray-600 dark:text-gray-400 dark:bg-gray-900">
      <Spinner />
    </div>
  )
}

export default ThemedSuspense
